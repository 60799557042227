<template>
  <v-sheet color="grey-ligthen-3">
      <v-container fluid>
      <v-data-table
        :headers="headers"
        item-key="competi_id"
        :items="extended_data"
        :options.sync="options"
        class="elevation-0"
        hide-default-footer
        @page-count="pageCount = $event"
        
      >
        <template v-slot:top>
          <v-toolbar flat dense rounded >
            <v-toolbar-title style="position: relative;left: 40%;">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark color="primary" @click="closeDialogue">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "extended",
  props: {
    extended_data: Array,
    headers: Array,
    title:String
  },
  data() {
    return {
      options: {},
    };
  },
  methods: {
    closeDialogue() {
      this.currentPage = 1;
      this.$emit("clsdlg", "");
    },
  },
};
</script>
